import React, { useState } from "react";
import PageLayout from "../components/pagelayout";
import Image from "../components/image";
import SEO from "../components/seo";
import portfolio from "../components/portfolio.json";

const Portfolio = () => {
  const appImages = portfolio.filter(image => image.type === "app");
  const productImages = portfolio.filter(image => image.type === "product");

  const [shownImages, setShownImages] = useState(portfolio);
  const [selectedTab, setSelectedTab] = useState("all");

  return (
    <PageLayout>
      <SEO title="Portfolio" />
      <div id="filters">
        <button
          className={`tab-links ${selectedTab === "all" && "tab-selected"}`}
          onClick={() => {
            setShownImages(portfolio);
            setSelectedTab("all");
          }}
        >
          All
        </button>
        <button
          className={`tab-links ${selectedTab === "apps" && "tab-selected"}`}
          onClick={() => {
            setShownImages(appImages);
            setSelectedTab("apps");
          }}
        >
          Apps
        </button>
        <button
          className={`tab-links ${
            selectedTab === "products" && "tab-selected"
          }`}
          onClick={() => {
            setShownImages(productImages);
            setSelectedTab("products");
          }}
        >
          Products
        </button>
      </div>
      <div id="portfolio-container">
        {shownImages.map(image => (
          <div className="portfolio-item" key={image.key}>
            <Image name={image.key + "Portfolio"} className="portfolio-image" />
            <div className="portfolio-overlay">
              <h2 className="image-name">{image.name}</h2>
              <p className="image-description">{image.description}</p>
            </div>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

export default Portfolio;
